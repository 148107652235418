import {
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Button,
    Flex,
    Heading,
    Input,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Alert,
    AlertIcon,
    Card,
    CardBody,
    Stack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { Pagination } from "../../Components/Pagination";
const CreditDetails = () => {
    const invoiceDetails = [
        {
            "biller_name": "Rohan",
            "invoice_no": "1E85BG",
            "invoice_date": "10-05-2023",
            "biller_mobile": "7896541230",
            "paid_amount": 500,
            "remaining_amount": 300,
            "total_amount": 800
        },
        {
            "biller_name": "Zabir",
            "invoice_no": "2C8INO",
            "invoice_date": "16-06-2023",
            "biller_mobile": "1234567890",
            "paid_amount": 500,
            "remaining_amount": 300,
            "total_amount": 800
        },
        {
            "biller_name": "Karim",
            "invoice_no": "3XCDRQ",
            "invoice_date": "10-05-2023",
            "biller_mobile": "2345678910",
            "paid_amount": 500,
            "remaining_amount": 300,
            "total_amount": 800
        },
        {
            "biller_name": "Rohan",
            "invoice_no": "4REGTU",
            "invoice_date": "12-05-2023",
            "biller_mobile": "7896541230",
            "paid_amount": 1500,
            "remaining_amount": 500,
            "total_amount": 2000
        },
        {
            "biller_name": "Rohan",
            "invoice_no": "55IONR",
            "invoice_date": "23-07-2023",
            "biller_mobile": "7896541230",
            "paid_amount": 300,
            "remaining_amount": 700,
            "total_amount": 1000
        },
        {
            "biller_name": "Pandey",
            "invoice_no": "6UIM85",
            "invoice_date": "12-02-2023",
            "biller_mobile": "8910111213",
            "paid_amount": 500,
            "remaining_amount": 300,
            "total_amount": 800
        },
        {
            "biller_name": "Pandey",
            "invoice_no": "7BHIO9",
            "invoice_date": "25-11-2023",
            "biller_mobile": "8910111213",
            "paid_amount": 2100,
            "remaining_amount": 400,
            "total_amount": 2500
        },
    ]

    const [searchQuery, setSearchQuery] = useState("");
    const [page, setPage] = useState(1);
    let perPage = 10;

    // Check if taskData is an array, otherwise initialize it as an empty array
    let invoiceDataArray = Array.isArray(invoiceDetails) ? invoiceDetails : [invoiceDetails];

    // Calculate total pages based on the filtered data
    let totalPage = Math.ceil(invoiceDataArray.length / perPage);
    let end = page * perPage;
    let start = end - perPage;

    // Apply filtering on the taskDataArray and handle non-array cases
    let filteredData = Array.isArray(invoiceDataArray)
        ? invoiceDataArray.filter(
            (el) =>
                (el.biller_mobile &&
                    el.biller_mobile.toString().includes(searchQuery.toLowerCase())) ||
                (el.invoice_no &&
                    el.invoice_no.toLowerCase().includes(searchQuery.toLowerCase()))
        )
        : [invoiceDataArray];

    // Apply pagination to the filteredData
    let paginationData = filteredData.slice(start, end);

    //console.log("paginationData", paginationData);

    // Handling Pagination End Here
    const [totalsAll, setTotalsAll] = useState({
        totalAmount: 0,
        paidAmount: 0,
        remainingAmount: 0,
    });



    const [totalAmountSum, setTotalAmountSum] = useState(0);
    const [paidAmountSum, setPaidAmountSum] = useState(0);
    const [remainingAmountSum, setRemainingAmountSum] = useState(0);
    useEffect(() => {
        // Calculate sums based on paginationData
        const calculateSums = () => {
            let totalSum = 0;
            let paidSum = 0;
            let remainingSum = 0;

            paginationData.forEach((el) => {
                totalSum += el.total_amount || 0;
                paidSum += el.paid_amount || 0;
                remainingSum += el.remaining_amount || 0;
            });

            setTotalAmountSum(totalSum);
            setPaidAmountSum(paidSum);
            setRemainingAmountSum(remainingSum);
        };

        calculateSums();
    }, [paginationData]);

    const [totalAmountAll, setTotalAmountAll] = useState(0);
    const [paidAmountAll, setPaidAmountAll] = useState(0);
    const [remainingAmountAll, setRemainingAmountAll] = useState(0);
    useEffect(() => {
        // Calculate sums based on paginationData
        const calculateSums = () => {
            let totalSum = 0;
            let paidSum = 0;
            let remainingSum = 0;

            invoiceDetails.forEach((el) => {
                totalSum += el.total_amount || 0;
                paidSum += el.paid_amount || 0;
                remainingSum += el.remaining_amount || 0;
            });
            setTotalsAll({
                totalAmount: totalSum,
                paidAmount: paidSum,
                remainingAmount: remainingSum,
            });

            setTotalAmountAll(totalSum);
            setPaidAmountAll(paidSum);
            setRemainingAmountAll(remainingSum);
        };

        calculateSums();
    }, [invoiceDetails]);
    return (
        <Flex>
            <Box pl={{ base: "none", md: "10" }} w={{ base: "100%", md: "100%" }}>
                <Flex
                    flexWrap="wrap"
                    mb={4}
                    mt={10}
                    direction={{ base: "column", md: "row" }}
                    justifyContent={{ base: "flex-start", md: "space-around" }}
                >
                    <Card
                        w={250}
                        bgGradient="linear(to-r, teal.300,  teal.100)"
                        _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
                    >
                        <CardBody>
                            <Stack
                                spacing="3"
                                textAlign="center"
                                align="center"
                                justify="center"
                            >
                                <Heading size="sm">Total Amount</Heading>
                                <Button size="sm" color={"cyan.600"} w={20}>
                                    {/* Rs {totalAmountAll.toFixed(2)} */}
                                    Rs {searchQuery ? totalAmountSum.toFixed(2) : totalsAll.totalAmount.toFixed(2)}
                                </Button>
                            </Stack>
                        </CardBody>
                    </Card>
                    <Card
                        w={250}
                        bgGradient="linear(to-r, teal.300,  teal.100)"
                        _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
                    >
                        <CardBody>
                            <Stack
                                spacing="3"
                                textAlign="center"
                                align="center"
                                justify="center"
                            >
                                <Heading size="sm">Total Paid Amount</Heading>
                                <Button size="sm" color={"cyan.600"} w={20}>
                                    {/* Rs {paidAmountAll.toFixed(2)} */}
                                    Rs {searchQuery ? paidAmountSum.toFixed(2) : totalsAll.paidAmount.toFixed(2)}
                                </Button>
                            </Stack>
                        </CardBody>
                    </Card>
                    <Card
                        bgGradient="linear(to-r, teal.300,  teal.100)"
                        _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
                        w={250}
                    >
                        <CardBody>
                            <Stack
                                spacing="3"
                                textAlign="center"
                                align="center"
                                justify="center"
                            >
                                <Heading size="sm">Total Remaining Amount</Heading>
                                <Button size="sm" color={"cyan.600"} w={20}>
                                    {/* Rs {remainingAmountAll.toFixed(2)} */}
                                    Rs {searchQuery ? remainingAmountSum.toFixed(2) : totalsAll.remainingAmount.toFixed(2)}
                                </Button>
                            </Stack>
                        </CardBody>
                    </Card>
                </Flex>
                <Heading size={"md"} textAlign={"start"}>
                    Invoice Report
                </Heading>
                <Flex justifyContent={"space-between"}>
                    <Breadcrumb
                        spacing="8px"
                        mt={6}
                        mb={6}
                        separator={<ChevronRightIcon color="gray.500" />}
                    >
                        <BreadcrumbItem>
                            <BreadcrumbLink href="/dashboard">Dashboard</BreadcrumbLink>
                        </BreadcrumbItem>

                        <BreadcrumbItem>
                            <BreadcrumbLink href="#">Invoice Report</BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>
                    <Flex justifyContent={"space-between"} gap={3}>
                        <Input
                            htmlSize={20}
                            width={300}
                            placeholder="Search By Invoice No, Name, Mobile"
                            focusBorderColor="green.400"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </Flex>
                </Flex>
                <Box>
                    <TableContainer>
                        <Table variant="simple" colorScheme="teal" boxShadow={"md"}>
                            <Thead>
                                <Tr boxShadow={"md"}>
                                    {/* <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                                        See Details
                                    </Th> */}
                                    <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                                        Biller Name
                                    </Th>
                                    <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                                        Invoice No
                                    </Th>
                                    <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                                        Billing Date
                                    </Th>
                                    <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                                        Mobile
                                    </Th>
                                    <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                                        Total Amount
                                    </Th>
                                    <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                                        Paid Amount
                                    </Th>
                                    <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                                        Remaining Amount
                                    </Th>

                                </Tr>
                            </Thead>

                            <Tbody>
                                {paginationData.length > 0 &&
                                    paginationData?.map((el) => {
                                        return (
                                            <Tr key={el.id} boxShadow={"md"}>
                                                {/* <Td style={{ textAlign: "center" }}>
                                                    <Link to={{ pathname: `/invoice-details/${el.id}` }}>
                                                    <Button size={"xs"} bgGradient="linear(to-r, teal.300,  teal.100)"
                                                        _hover={{
                                                            bgGradient: "linear(to-r, teal.100,  teal.400)",
                                                        }}

                                                    >View</Button>
                                                    </Link>
                                                </Td> */}
                                                <Td style={{ textAlign: "center" }}>
                                                    <Button size={"xs"} bgGradient="linear(to-r, teal.300,  teal.100)"
                                                        _hover={{
                                                            bgGradient: "linear(to-r, teal.100,  teal.400)",
                                                        }}>{el.biller_name}</Button>
                                                </Td>
                                                <Td style={{ textAlign: "center" }}>
                                                    <Button size={"xs"} bgGradient="linear(to-r, teal.300,  teal.100)"
                                                        _hover={{
                                                            bgGradient: "linear(to-r, teal.100,  teal.400)",
                                                        }}>{el.invoice_no}</Button>
                                                </Td>
                                                <Td style={{ textAlign: "center" }}>
                                                    <Button size={"xs"} bgGradient="linear(to-r, teal.300,  teal.100)"
                                                        _hover={{
                                                            bgGradient: "linear(to-r, teal.100,  teal.400)",
                                                        }}>{el.invoice_date}</Button>
                                                </Td>
                                                <Td style={{ textAlign: "center" }}>
                                                    <Button size={"xs"} bgGradient="linear(to-r, teal.300,  teal.100)"
                                                        _hover={{
                                                            bgGradient: "linear(to-r, teal.100,  teal.400)",
                                                        }}>{el.biller_mobile}</Button>
                                                </Td>
                                                <Td style={{ textAlign: "center" }}>
                                                    <Button size={"xs"} bgGradient="linear(to-r, teal.300,  teal.100)"
                                                        _hover={{
                                                            bgGradient: "linear(to-r, teal.100,  teal.400)",
                                                        }}>
                                                        {el.total_amount}
                                                    </Button>
                                                </Td>

                                                <Td style={{ textAlign: "center" }}>
                                                    <Button size={"xs"} bgGradient="linear(to-r, teal.300,  teal.100)"
                                                        _hover={{
                                                            bgGradient: "linear(to-r, teal.100,  teal.400)",
                                                        }}>{el.paid_amount}</Button>
                                                </Td>

                                                <Td style={{ textAlign: "center" }}>
                                                    <Button size={"xs"} bgGradient="linear(to-r, teal.300,  teal.100)"
                                                        _hover={{
                                                            bgGradient: "linear(to-r, teal.100,  teal.400)",
                                                        }}>{el.remaining_amount}</Button>
                                                </Td>
                                            </Tr>
                                        );
                                    })}
                            </Tbody>
                        </Table>
                        <Box>
                            {paginationData.length === 0 && (
                                <Alert
                                    status="info"
                                    variant="subtle"
                                    flexDirection="column"
                                    alignItems="center"
                                    justifyContent="center"
                                    mt={4}
                                >
                                    <AlertIcon boxSize={8} />
                                    No Matches Found
                                </Alert>
                            )}
                        </Box>
                    </TableContainer>
                    
                    <Flex justifyContent={"flex-end"} mt={5}>
                        <Pagination page={page} setPage={setPage} totalPage={totalPage} />
                    </Flex>
                </Box>
            </Box>
        </Flex>
    );
};

export default CreditDetails;
