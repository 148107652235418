import * as types from "./actionTypes";
const initialState = {
  addedStocks: [],
  salesStocks: [],
  invoiceDetails: [],
  returnedItems: [],
  isLoading: false,
  isError: false,
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    default:
      return state;
    case types.GET_ADDEDSTOCKS_REQUEST:
      return { ...state, isLoading: true, isError: false };
    case types.GET_ADDEDSTOCKS_SUCCESS:
      return {
        ...state,
        addedStocks: payload,
        isLoading: false,
        isError: false,
      };
    case types.GET_ADDEDSTOCKS_FAILURE:
      return { ...state, addedStocks: [], isLoading: false, isError: true };

    case types.EDIT_STOCK_REQUEST:
      return { ...state, isLoading: true, isError: false };
    case types.EDIT_STOCK_SUCCESS:
      return {
        ...state,
        addedStocks: payload,
        isLoading: false,
        isError: false,
      };
    case types.EDIT_STOCK_FAILURE:
      return { ...state, addedStocks: [], isLoading: false, isError: true };

    case types.GET_SALES_STOCKS_REQUEST:
      return { ...state, isLoading: true, isError: false };
    case types.GET_SALES_STOCKS_SUCCESS:
      return {
        ...state,
        salesStocks: payload,
        isLoading: false,
        isError: false,
      };
    case types.GET_SALES_STOCKS_FAILURE:
      return { ...state, salesStocks: [], isLoading: false, isError: true };

    case types.GET_INVOICE_REQUEST:
      return { ...state, isLoading: true, isError: false };
    case types.GET_INVOICE_SUCCESS:
      return {
        ...state,
        invoiceDetails: payload,
        isLoading: false,
        isError: false,
      };
    case types.GET_INVOICE_FAILURE:
      return { ...state, invoiceDetails: [], isLoading: false, isError: true };
    case types.GET_RETURNED_REQUEST:
      return { ...state, isLoading: true, isError: false };
    case types.GET_RETURNED_SUCCESS:
      return {
        ...state,
        returnedItems: payload,
        isLoading: false,
        isError: false,
      };
    case types.GET_RETURNED_FAILURE:
      return { ...state, returnedItems: [], isLoading: false, isError: true };
  }
};
