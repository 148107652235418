import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../Pages/Login";
import Dashboard from "../Pages/Dashboard";
import Stocks from "../Pages/Stocks";
import Reports from "../Pages/Reports";
import Sales from "../Pages/Sales";
import AddStockPage from "../Pages/AddStockPage";
import GenerateInvoice from "../Pages/GenerateInvoice";
import RequiredAuth from "../PrivateRoute/RequiredAuth";
import ReportSingleDetails from "../Pages/ReportSingleDetails";
import Returns from "../Pages/Returns/Returns";
import ChangePassword from "../Pages/Password/ChangePassword";
import ResetPassword from "../Pages/Password/ResetPassword";
import EnterEmail from "../Pages/Password/EnterEmail";
import Credits from "../Pages/Credits/Credits";
import CreditDetails from "../Pages/Credits/CreditDetails";
import ReturnItems from "../Pages/Returns/ReturnedItems";
import ReturnUpdates from "../Pages/Returns/ReturnUpdates";


const AllRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/set-email" element={<EnterEmail />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/dashboard" element={<RequiredAuth><Dashboard /></RequiredAuth>} />
        <Route path="/stockcontrol" element={<RequiredAuth><Stocks /></RequiredAuth>} />
        <Route path="/addstocks" element={<RequiredAuth><AddStockPage /></RequiredAuth>} />
        <Route path="/reports" element={<RequiredAuth><Reports /></RequiredAuth>} />
        <Route path="/sales" element={<RequiredAuth><Sales /></RequiredAuth>} />
        <Route path="/returns" element={<RequiredAuth><Returns /></RequiredAuth>} />
        <Route path="/returnedItems" element={<RequiredAuth><ReturnUpdates /></RequiredAuth>} />
        <Route path="/generateinvoice" element={<RequiredAuth><GenerateInvoice /></RequiredAuth>} />
        <Route path="/invoice-details/:id" element={<RequiredAuth><ReportSingleDetails /></RequiredAuth>} />
        <Route path="/credit" element={<Credits />} />
        <Route path="/credit-details" element={<CreditDetails />} />
      </Routes>
    </>
  );
};

export default AllRoutes;
