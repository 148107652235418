import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import Navbar from "../Components/Navbar";
import Sidebar from "../Components/Sidebar";

import ReportsDetails from "./ReportsDetails";
const Reports = () => {


  return (
    <Flex>
      <Sidebar />
      <Box flex="1" p="4">
        <Navbar />
        <ReportsDetails />
      </Box>
    </Flex>
  );
};

export default Reports;
