import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  Input,
  FormControl,
  InputGroup,
  InputLeftAddon,
  Button,
  ListItem,
  List,
  useToast,
  ListIcon,
  Image
} from "@chakra-ui/react";
import Navbar from "../Components/Navbar";
import Sidebar from "../Components/Sidebar";
import html2pdf from "html2pdf.js"
import { MdCheckCircle } from "react-icons/md";
import { useParams } from "react-router-dom";
import { getInvoiceDteails } from "../Redux/app/action";
import { useDispatch, useSelector } from "react-redux";
import logo from "../assets/logo.jpg"
import { format } from "date-fns";
const ReportSingleDetails = () => {
  const toast = useToast();
  const { id } = useParams();
  //console.log("id", id)

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInvoiceDteails());
  }, [dispatch]);

  const invoiceDetails = useSelector((state) => state.AppReducer.invoiceDetails);
  //console.log("invoiceDetails", invoiceDetails)

  // const filteredData = invoiceDetails.filter((item) => item.id === id);
  const filteredData = id ? invoiceDetails.filter((item) => item.id == id) : [];

  //console.log("filteredDataReport", filteredData);



  const calculateTotal = () => {
    return filteredData.reduce((total, invoice) => {
      return (
        total +
        invoice.sales.reduce((subTotal, item) => subTotal + item.quantity * item.rate, 0)
      );
    }, 0);
  };

  const calculateWithTax = () => {
    const subTotal = calculateTotal();
    const salesTax = 0.14 * subTotal;
    return salesTax;
  };

  const calculateTotalWithTax = () => {
    const subTotal = calculateTotal();
    const salesTax = 0.14 * subTotal;
    const total = subTotal + salesTax;
    return total;
  };

  const[cash, setCash] = useState("0");
  const[upi, setUpi]= useState("0");
  const[credit,setCredit]= useState("");

  const totalCredit =calculateTotalWithTax() - (parseFloat(cash) + parseFloat(upi));

  const handleGenerateInvoice = () => {
    const pdfFileName = `${filteredData.map(item => item.invoice_no)}_Invoice.pdf`;
    var element = document.getElementById('element-to-print');
    html2pdf(element, { filename: pdfFileName });
    toast({
      position: "top",
      title: "Invoice Downloaded Successfully",
      description: `You can print your invoice from download`,
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  }


  return (
    <Flex>
      <Sidebar />
      <Box flex="1" p="4">
        <Navbar />
        <Box
          p={20}
          paddingX={{ base: 2, sm: 4, md: 8, lg: 12, xl: 16 }}
          paddingY={{ base: 2, sm: 4, md: 8, lg: 12, xl: 16 }}
          id="element-to-print"
        >
          <Flex justifyContent={"center"}><Heading fontWeight={"bold"} as='h3' size='lg'>Invoice</Heading></Flex>
          <Flex justifyContent={"space-between"} p={5}>
            <Box textAlign={"start"}>
            <Heading fontWeight={"bold"} as='h4' size='md'>Bharat Enterprises</Heading>
              <Flex justifyContent={"flex-start"} gap={2}><Text fontWeight={"bold"}></Text> </Flex>
              <Flex justifyContent={"flex-start"} gap={2}><Text fontWeight={"bold"}>Address:</Text></Flex>
              <Text>Sy No: GR P.NO 7-231/2</Text>
              <Text>Beside Indane Petrol Pump</Text>
              <Text>Shant Nagar Bhankur</Text>
              <Text>TQ Shahabad, 585228</Text>
              <Flex justifyContent={"flex-start"} gap={2}><Text fontWeight={"bold"}>Phone No:</Text> 9620819047</Flex>
              <Text>7795746499, 8904915905</Text>
            </Box>
            <Box>
              
              <Image src={logo}
                alt="Logo"
                boxSize="150px"
                objectFit="contain" />
            </Box>
          </Flex>
          <Box>
            <Flex justifyContent={"space-between"} p={5}>
              <Box textAlign={"start"}>
                <Text fontWeight={"bold"} mb={2}>Bill To :</Text>


                <FormControl id="biller_name">
                  <Input
                    type="text"
                    placeholder="Biller Name"
                    value={filteredData.length > 0 ? filteredData.map(item => item.biller_name).join(', ') : ''}
                    required
                    size="sm"
                    focusBorderColor="green.400"
                  />
                </FormControl>
                <FormControl id="biller_phone">
                  <Input
                    type="text"
                    placeholder="Biller Mobile"
                    value={filteredData.length > 0 ? filteredData.map(item => item.biller_mobile).join(', ') : ''}

                    required
                    size="sm"
                    focusBorderColor="green.400"
                    maxLength={10}
                  />
                </FormControl>
                <FormControl id="biller_address">
                  <Input
                    type="text"
                    placeholder="Biller Address"
                    value={filteredData.length > 0 ? filteredData.map(item => item.biller_address).join(', ') : ''}

                    required
                    size="sm"
                    focusBorderColor="green.400"
                  />
                </FormControl>
                <FormControl id="biller_city">
                  <Input
                    type="text"
                    placeholder="Biller City"
                    value={filteredData.length > 0 ? filteredData.map(item => item.biller_city).join(', ') : ''}

                    required
                    size="sm"
                    focusBorderColor="green.400"
                  />
                </FormControl>
                <FormControl id="order_id">
                  <Input
                    type="text"
                    placeholder="Biller State"
                    value={filteredData.length > 0 ? filteredData.map(item => item.biller_state).join(', ') : ''}

                    required
                    size="sm"
                    focusBorderColor="green.400"
                  />
                </FormControl>
              </Box>
              <Box pt={5}>
                <InputGroup size="sm">
                  <InputLeftAddon style={{marginRight:10}}>Invoice No</InputLeftAddon>
                  <Input
                    type="tel"
                    placeholder="Invoice Number"
                    focusBorderColor="green.400"
                    value={filteredData.length > 0 ? filteredData.map(item => item.invoice_no).join(', ') : ''}

                    required
                    bgGradient="linear(to-r, teal.300,  teal.100)"
                    _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
                  />
                </InputGroup>
                <InputGroup size="sm" >
                  <InputLeftAddon>Invoice Date</InputLeftAddon>
                  <Input
                    placeholder="Invoice Date"
                    focusBorderColor="green.400"
                    // value={filteredData.length > 0 ? filteredData.map(item => item.invoice_date).join(', ') : ''}
                    value={filteredData.length > 0 ? format(new Date(filteredData[0].invoice_date), 'dd-MM-yyyy') : ''}
                    required
                    bgGradient="linear(to-r, teal.300,  teal.100)"
                    _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
                  />
                </InputGroup>
              </Box>
            </Flex>
            <Table variant="simple" colorScheme="teal" >
              <Thead>
                <Tr >
                  <Th  style={{ textAlign: "center" }}>Name</Th>
                  <Th style={{ textAlign: "center" }}>HSN/SAC</Th>
                  <Th  style={{ textAlign: "center" }} isNumeric>Quantity</Th>
                  <Th style={{ textAlign: "center" }} isNumeric>Price</Th>
                  <Th  style={{ textAlign: "center" }} isNumeric>Total</Th>

                </Tr>
              </Thead>
              <Tbody>
                {filteredData.map((invoice, index) => (

                  invoice.sales.map((item) => (

                    <Tr key={item.id}>
                      <Td style={{ textAlign: "center" }}>{item.name}</Td>
                      <Td style={{ textAlign: "center" }}>{item.order_id}</Td>
                      <Td style={{ textAlign: "center" }}>{item.quantity}</Td>
                      <Td style={{ textAlign: "center" }}>{item.rate}</Td>
                      <Td style={{ textAlign: "center" }} isNumeric>
                        Rs {item.quantity * item.rate}
                      </Td>
                    </Tr>
                  ))
                ))}
              </Tbody>

            </Table>
            <Flex justifyContent={"flex-end"} flexDirection={"column"} alignItems={"end"}>
              <Flex justifyContent={"space-around"} bgGradient="linear(to-r, teal.300,  teal.100)"
                _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
                p={2}
                borderRadius={10}
                w={250}
                mt={5}>
                <Text textAlign={"start"} fontWeight={"bold"}>Sub Total:</Text>
                <Text textAlign={"end"} fontWeight={"bold"}>
                  Rs {filteredData.length > 0 ? filteredData.map(item => item.sub_total).join(', ') : ''}
                </Text>
              </Flex>
              <Flex justifyContent={"space-around"} bgGradient="linear(to-r, teal.300,  teal.100)"
                _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
                p={2}
                borderRadius={10}
                w={250}
                mt={5}>
                <Text textAlign={"start"} fontWeight={"bold"}>GST {filteredData.length > 0 ? filteredData.map(item => item.gst_in_percent).join(', ') : ''}% :</Text>
                <Text textAlign={"end"} fontWeight={"bold"}>
                  Rs {filteredData.length > 0 ? filteredData.map(item => item.gst_amount).join(', ') : ''}
                </Text>
              </Flex>
              <Flex justifyContent={"space-around"} bgGradient="linear(to-r, teal.300,  teal.100)"
                _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
                p={2}
                borderRadius={10}
                w={250}
                mt={5}>
                <Text textAlign={"start"} fontWeight={"bold"}>Total:</Text>
                <Text textAlign={"end"} fontWeight={"bold"}>
                  Rs {filteredData.length > 0 ? filteredData.map(item => item.total_amount).join(', ') : ''}
                </Text>
              </Flex>
        
            <Flex justifyContent={"space-around"} bgGradient="linear(to-r, teal.300,  teal.100)"
                _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
                p={2}
                borderRadius={10}
                w={250}
                mt={5}>
                  <InputGroup size="sm" >
                  <InputLeftAddon>Cash</InputLeftAddon>
                  <Input
                    type="number"
                    placeholder="Cash"
                    focusBorderColor="green.400"
                    value={cash}
                    onChange={(e) => setCash(e.target.value)}
                    required
                    bgGradient="linear(to-r, teal.300,  teal.100)"
                    _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
                  />
                </InputGroup>
                </Flex>
                <Flex justifyContent={"space-around"} bgGradient="linear(to-r, teal.300,  teal.100)"
                _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
                p={2}
                borderRadius={10}
                w={250}
                mt={5}>
                  <InputGroup size="sm" >
                  <InputLeftAddon>UPI</InputLeftAddon>
                  <Input
                    type="number"
                    placeholder="UPI"
                    focusBorderColor="green.400"
                    value={upi}
                    onChange={(e) => setUpi(e.target.value)}
                    required
                    bgGradient="linear(to-r, teal.300,  teal.100)"
                    _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
                  />
                </InputGroup>
                </Flex>
                <Flex justifyContent={"space-around"} bgGradient="linear(to-r, teal.300,  teal.100)"
                _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
                p={2}
                borderRadius={10}
                w={250}
                mt={5}>
                  <InputGroup size="sm" >
                  <InputLeftAddon>Credit</InputLeftAddon>
                  <Input
                    type="number"
                    placeholder="Credit"
                    focusBorderColor="green.400"
                    value={totalCredit.toFixed(2)}
                    onChange={(e) => setCredit(e.target.value)}
                    required
                    bgGradient="linear(to-r, teal.300,  teal.100)"
                    _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
                  />
                </InputGroup>
                </Flex>
            </Flex>
            
            <Text textAlign={"start"} fontWeight={"bold"}>Note: </Text>

            <List spacing={3} textAlign={"start"}>
              <ListItem>
                <ListIcon as={MdCheckCircle} color='green.500' />
                It was great doing business with you
              </ListItem>
            </List>
            <Text textAlign={"start"} fontWeight={"bold"} mt={5}>Terms & Conditions: </Text>

            <List spacing={3} textAlign={"start"}>
              <ListItem>
                <ListIcon as={MdCheckCircle} color='green.500' />
                Please review the bill before leaving the counter
              </ListItem>
            </List>

          </Box>
        </Box>
        <Flex justifyContent={"center"}>
          <Button
            mt={4}
            onClick={handleGenerateInvoice}
            bgGradient="linear(to-r, teal.300,  teal.100)"
            _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
            w="40%"
            fontWeight={"bold"}
          >
            Download Invoice
          </Button>

        </Flex>
      </Box>
    </Flex>
  );
};

export default ReportSingleDetails;
