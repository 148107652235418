import { Box, Button, Flex } from "@chakra-ui/react";
import React from "react";

export const Pagination = ({ page, setPage, totalPage }) => {
  const ButtonStyle = {
    color: "green",
    variant: "outline",
    colorScheme: "green",
    size: "sm",
    
  };

  const DisabledButtonStyle = {
    ...ButtonStyle,
    _disabled: {
        colorScheme: "blue",
    },
  };

  const handlePrevClick = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextClick = () => {
    if (page < totalPage) {
      setPage(page + 1);
    }
  };

  const getPageRange = () => {
    if (totalPage === 0) {
      return 0;
    } else {
      return `${page} - ${totalPage}`;
    }
  };

  return (
    <Box>
      <Flex justifyContent="center" gap={3}>
        <Button
         {...(page === 1 ? DisabledButtonStyle : ButtonStyle)}
         onClick={handlePrevClick}
         disabled={page === 1}
         size={"xs"}
        >
          Prev
        </Button>
        <Button {...ButtonStyle} disabled size={"xs"}>{getPageRange()}</Button>
        <Button
           {...(page === totalPage  ? DisabledButtonStyle : ButtonStyle)}
           onClick={handleNextClick}
           disabled={page === totalPage}
           size={"xs"}
        >
          Next
        </Button>
      </Flex>
    </Box>
  );
};
