import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Heading,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Alert,
  AlertIcon,
  InputRightElement,
  InputGroup,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { getInvoiceDteails, seeSalesStocks } from "../Redux/app/action";
import { Pagination } from "../Components/Pagination";
import { format } from "date-fns";
import { BsCalendar3 } from "react-icons/bs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./datepicker-custom.css";
const SalesStocks = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(seeSalesStocks());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getInvoiceDteails());
  }, [dispatch]);

  const salesStocks = useSelector((state) => state.AppReducer.salesStocks);
  //console.log("salesStocks", salesStocks);

  const invoiceDetails = useSelector(
    (state) => state.AppReducer.invoiceDetails
  );
  //console.log("invoiceDetails", invoiceDetails);
  const addedStocks = useSelector((state) => state.AppReducer.addedStocks);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  let perPage = 10;

  // Check if taskData is an array, otherwise initialize it as an empty array
  let salesDataArray = Array.isArray(salesStocks) ? salesStocks : [salesStocks];

  const dataWithPurchaseTotal = salesDataArray.map((el) => {
    // Find the corresponding addedStock for the current paginationData order_id
    const matchingAddedStock = addedStocks.find(
      (addedStock) => addedStock.order_id === el.order_id
    );
    const matchingInvoice = invoiceDetails.find(
      (invoice) => invoice.id === el.invoice_id
    );
    const invoiceNo = matchingInvoice ? matchingInvoice.invoice_no : null;
    //console.log("invoiceNo",invoiceNo)
    // Calculate the total purchase rate (purchase rate * quantity)
    const totalPurchaseRate = matchingAddedStock
      ? matchingAddedStock.purchase_rate * el.quantity
      : 0;

    // Return the original data along with the new totalPurchaseRate property
    return {
      ...el,
      totalPurchaseRate: totalPurchaseRate,
      invoiceNo: invoiceNo,
    };
  });

  //console.log("dataWithPurchaseTotal", dataWithPurchaseTotal);

  // Calculate total pages based on the filtered data
  let totalPage = Math.ceil(dataWithPurchaseTotal.length / perPage);
  let end = page * perPage;
  let start = end - perPage;

  // Apply filtering on the taskDataArray and handle non-array cases
  let filteredData = Array.isArray(dataWithPurchaseTotal)
    ? dataWithPurchaseTotal.filter(
        (el) =>
          (el.name &&
            el.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (el.order_id &&
            el.order_id.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (el.created_at &&
            el.created_at.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (el.invoiceNo &&
            el.invoiceNo.toLowerCase().includes(searchQuery.toLowerCase()))
      )
    : [salesDataArray];

  // Apply pagination to the filteredData
  let paginationData = filteredData.slice(start, end);

  //console.log("paginationData", paginationData);

  // Handling Pagination End Here

  // const [selectedStartDate, setSelectedStartDate] = useState(null);
  // const [selectedEndDate, setSelectedEndDate] = useState(null);

  // const handleStartDateChange = (date) => {
  //   setSelectedStartDate(date);
  // };

  // const handleEndDateChange = (date) => {
  //   setSelectedEndDate(date);
  // };

  // const filteredDataDateFiltration = paginationData.filter((item) => {
  //   const itemDate = new Date(item.created_at);
  //   return (
  //     (!selectedStartDate || itemDate >= selectedStartDate) &&
  //     (!selectedEndDate || itemDate <= selectedEndDate)
  //   );
  // });

  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);

  //console.log(selectedStartDate, selectedEndDate);

  const handleStartDateChange = (date) => {
    const utcStartDate = date ? new Date(date.toISOString()) : null;
    setSelectedStartDate(utcStartDate);
  };

  const handleEndDateChange = (date) => {
    const utcEndDate = date ? new Date(date.toISOString()) : null;
    setSelectedEndDate(utcEndDate);
  };
  const filteredDataDateFiltration = paginationData.filter((item) => {
    const itemDate = new Date(item.created_at);
    itemDate.setHours(0, 0, 0, 0);
    const utcItemDate = new Date(itemDate.toISOString());
  
    return (
      (!selectedStartDate || utcItemDate >= selectedStartDate) &&
      (!selectedEndDate || utcItemDate <= selectedEndDate)
    );
  });
  

  //console.log("toandfrom", filteredDataDateFiltration);


  return (
    <Flex>
      <Box pl={{ base: "none", md: "10" }} w={{ base: "100%", md: "100%" }}>
        <br />
        <br />
        <Heading size={"md"} textAlign={"start"}>
          Sales Report
        </Heading>
        <Flex justifyContent={"space-between"}>
          <Breadcrumb
            spacing="8px"
            mt={6}
            mb={6}
            separator={<ChevronRightIcon color="gray.500" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink href="/dashboard">Dashboard</BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem>
              <BreadcrumbLink href="#">Sales Report</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <Flex justifyContent={"space-between"} gap={3}>
            <Flex justifyContent={"space-between"} gap={1}>
              <DatePicker
                selected={selectedStartDate}
                onChange={handleStartDateChange}
                placeholderText="From Date"
                dateFormat="yyyy-MM-dd"
                customInput={
                  <InputGroup>
                    <Input
                      value={
                        selectedStartDate
                          ? format(selectedStartDate, "dd-MM-yyyy")
                          : ""
                      }
                      placeholder="From Date"
                      style={{
                        border: "1px solid gray",
                        fontWeight: "inherit",
                      }}
                    />
                    <InputRightElement>
                      <BsCalendar3 />
                    </InputRightElement>
                  </InputGroup>
                }
              />
              <DatePicker
                selected={selectedEndDate}
                onChange={handleEndDateChange}
                placeholderText="To Date"
                dateFormat="yyyy-MM-dd"
                customInput={
                  <InputGroup>
                    <Input
                      value={
                        selectedEndDate
                          ? format(selectedEndDate, "dd-MM-yyyy")
                          : ""
                      }
                      placeholder="To Date"
                      style={{
                        border: "1px solid gray",
                        fontWeight: "inherit",
                      }}
                    />
                    <InputRightElement>
                      <BsCalendar3 />
                    </InputRightElement>
                  </InputGroup>
                }
              />
            </Flex>
            <Input
              htmlSize={20}
              width={500}
              placeholder="Search By Order Id, Name, Description"
              focusBorderColor="green.400"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </Flex>
        </Flex>
        <Box>
          <TableContainer>
            <Table variant="simple" colorScheme="teal" boxShadow={"md"}>
              <Thead>
                <Tr boxShadow={"md"}>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Invoice No
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Bill Date
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Name
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    HSN/SAC
                  </Th>

                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Quantity
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Rate
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Total Amount
                  </Th>
                  {/* <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Profit/Loss
                  </Th> */}
                </Tr>
              </Thead>

              <Tbody>
                {filteredDataDateFiltration.length > 0 &&
                  filteredDataDateFiltration
                    ?.filter((el) => el.quantity > 0)
                    .map((el) => {
                      return (
                        <Tr key={el.id} boxShadow={"md"}>
                          <Td style={{ textAlign: "center" }}>
                            <Button
                              size={"xs"}
                              bgGradient="linear(to-r, teal.300,  teal.100)"
                              _hover={{
                                bgGradient: "linear(to-r, teal.100,  teal.400)",
                              }}
                            >
                              {el.invoiceNo}
                            </Button>
                          </Td>
                          <Td style={{ textAlign: "center" }}>
                            <Button
                              size={"xs"}
                              bgGradient="linear(to-r, teal.300,  teal.100)"
                              _hover={{
                                bgGradient: "linear(to-r, teal.100,  teal.400)",
                              }}
                            >
                              {format(new Date(el.created_at), "dd-MM-yyyy")}
                            </Button>
                          </Td>
                          <Td style={{ textAlign: "center" }}>
                            <Button
                              size={"xs"}
                              bgGradient="linear(to-r, teal.300,  teal.100)"
                              _hover={{
                                bgGradient: "linear(to-r, teal.100,  teal.400)",
                              }}
                            >
                              {el.name}
                            </Button>
                          </Td>

                          <Td style={{ textAlign: "center" }}>
                            <Button
                              size={"xs"}
                              bgGradient="linear(to-r, teal.300,  teal.100)"
                              _hover={{
                                bgGradient: "linear(to-r, teal.100,  teal.400)",
                              }}
                            >
                              {el.order_id}
                            </Button>
                          </Td>
                          <Td style={{ textAlign: "center" }}>
                            <Button
                              size={"xs"}
                              bgGradient="linear(to-r, teal.300,  teal.100)"
                              _hover={{
                                bgGradient: "linear(to-r, teal.100,  teal.400)",
                              }}
                            >
                              {el.quantity}
                            </Button>
                          </Td>
                          <Td style={{ textAlign: "center" }}>
                            <Button
                              size={"xs"}
                              bgGradient="linear(to-r, teal.300,  teal.100)"
                              _hover={{
                                bgGradient: "linear(to-r, teal.100,  teal.400)",
                              }}
                            >
                              Rs {el.rate}
                            </Button>
                          </Td>
                          <Td style={{ textAlign: "center" }}>
                            <Button
                              size={"xs"}
                              bgGradient="linear(to-r, teal.300,  teal.100)"
                              _hover={{
                                bgGradient: "linear(to-r, teal.100,  teal.400)",
                              }}
                            >
                              Rs {el.amount}
                            </Button>
                          </Td>
                          {/* <Td style={{ textAlign: "center" }}>
                                                    <Button size={"xs"} bgGradient="linear(to-r, teal.300,  teal.100)"
                                                        _hover={{
                                                            bgGradient: "linear(to-r, teal.100,  teal.400)",
                                                        }}>Rs {el.totalPurchaseRate}</Button>
                                                </Td> */}
                          {/* <Td style={{ textAlign: "center" }}>
                                                    <Button size={"xs"} bgGradient="linear(to-r, teal.300,  teal.100)"
                                                        _hover={{
                                                            bgGradient: "linear(to-r, teal.100,  teal.400)",
                                                        }}>Rs {el.amount-el.totalPurchaseRate}</Button>
                                                </Td> */}
                          {/* <Td style={{ textAlign: "center" }}>
                            <Button
                              size={"xs"}
                              bgGradient={`linear(to-r, ${
                                el.amount - el.totalPurchaseRate < 0
                                  ? "red.300, red.100"
                                  : "teal.300, teal.100"
                              })`}
                              _hover={{
                                bgGradient: `linear(to-r, ${
                                  el.amount - el.totalPurchaseRate < 0
                                    ? "red.100, red.400"
                                    : "teal.100, teal.400"
                                })`,
                              }}
                            >
                              Rs {el.amount - el.totalPurchaseRate}
                            </Button>
                          </Td> */}
                        </Tr>
                      );
                    })}
              </Tbody>
            </Table>
            <Box>
              {filteredDataDateFiltration.length === 0 && (
                <Alert
                  status="info"
                  variant="subtle"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  mt={4}
                >
                  <AlertIcon boxSize={8} />
                  No Matches Found
                </Alert>
              )}
            </Box>
          </TableContainer>
          <Flex justifyContent={"flex-end"} mt={5}>
            <Pagination page={page} setPage={setPage} totalPage={totalPage} />
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
};

export default SalesStocks;
