import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Heading,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";

import { format } from "date-fns";
import {
  getInvoiceDteails,
  getReturnedItems,
  seeSalesStocks,
} from "../../Redux/app/action";
import { Pagination } from "../../Components/Pagination";
import { Link } from "react-router-dom";

const ReturnItems = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(seeSalesStocks());
    dispatch(getInvoiceDteails());
    dispatch(getReturnedItems());
  }, [dispatch]);

  const returnedItems = useSelector((state) => state.AppReducer.returnedItems);
  //console.log("returnedItems", returnedItems);
  const invoiceDetails = useSelector(
    (state) => state.AppReducer.invoiceDetails
  );

  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  let perPage = 10;

  // Check if taskData is an array, otherwise initialize it as an empty array
  let returnDataArray = Array.isArray(returnedItems)
    ? returnedItems
    : [returnedItems];

  const dataWithPurchaseTotal = returnDataArray.map((el) => {
    // Find the corresponding addedStock for the current paginationData order_id

    const matchingInvoice = invoiceDetails.find(
      (invoice) => invoice.id === el.invoice_id
    );
    const biller_name = matchingInvoice ? matchingInvoice.biller_name : null;
    //console.log("biller_name", biller_name);
    // Calculate the total purchase rate (purchase rate * quantity)

    // Return the original data along with the new totalPurchaseRate property
    return {
      ...el,
      biller_name: biller_name,
    };
  });

  // Calculate total pages based on the filtered data
  let totalPage = Math.ceil(dataWithPurchaseTotal.length / perPage);
  let end = page * perPage;
  let start = end - perPage;

  // Apply filtering on the taskDataArray and handle non-array cases
  let filteredData = Array.isArray(dataWithPurchaseTotal)
    ? dataWithPurchaseTotal.filter(
        (el) =>
          (el.biller_name &&
            el.biller_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (el.invoice_no &&
            el.invoice_no.toLowerCase().includes(searchQuery.toLowerCase()))
      )
    : [dataWithPurchaseTotal];

  // Apply pagination to the filteredData
  let paginationData = filteredData.slice(start, end);
  //console.log("paginationData", paginationData);

  // Handling Pagination End Here

  return (
    <Flex>
      <Box pl={{ base: "none", md: "10" }} w={{ base: "100%", md: "100%" }}>
        <br />
        <br />
        <Heading size={"md"} textAlign={"start"}>
          Returned Items
        </Heading>
        <Flex justifyContent={"space-between"}>
          <Breadcrumb
            spacing="8px"
            mt={6}
            mb={6}
            separator={<ChevronRightIcon color="gray.500" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink href="/dashboard">Dashboard</BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem>
              <BreadcrumbLink href="#">Returned Items</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <Flex justifyContent={"space-between"} gap={3}>
            <Link to="/returns">
              <Button
                size={"md"}
                bgGradient="linear(to-r, teal.300,  teal.100)"
                _hover={{
                  bgGradient: "linear(to-r, teal.100,  teal.400)",
                }}
              >
                Go to Return
              </Button>
            </Link>
            <Link to="/returnedItems">
              <Button
                size={"md"}
                bgGradient="linear(to-r, teal.300,  teal.100)"
                _hover={{
                  bgGradient: "linear(to-r, teal.100,  teal.400)",
                }}
              >
                See Returned Items
              </Button>
            </Link>
            <Input
              htmlSize={20}
              width={500}
              placeholder="Search By Invoice Number To Return"
              focusBorderColor="green.400"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </Flex>
        </Flex>
        <Box>
          <TableContainer>
            <Table variant="simple" colorScheme="teal" boxShadow={"md"}>
              <Thead>
                <Tr boxShadow={"md"}>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Invoice No
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Returned Date
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Name
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                  HSN/SAC
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Biller Name
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Quantity Returned
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Rate
                  </Th>
                  <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    Returned Amount
                  </Th>
                </Tr>
              </Thead>

              <Tbody>
                {paginationData.length > 0 &&
                  paginationData
                    ?.filter((el) => el.quantity_returns > 0)
                    .map((el) => {
                      return (
                        <Tr key={el.id} boxShadow={"md"}>
                          <Td style={{ textAlign: "center" }}>
                            <Button
                              size={"xs"}
                              bgGradient="linear(to-r, teal.300,  teal.100)"
                              _hover={{
                                bgGradient: "linear(to-r, teal.100,  teal.400)",
                              }}
                            >
                              {el.invoice_no}
                            </Button>
                          </Td>
                          <Td style={{ textAlign: "center" }}>
                            <Button
                              size={"xs"}
                              bgGradient="linear(to-r, teal.300,  teal.100)"
                              _hover={{
                                bgGradient: "linear(to-r, teal.100,  teal.400)",
                              }}
                            >
                              {format(new Date(el.created_at), "dd-MM-yyyy")}
                            </Button>
                          </Td>
                          <Td style={{ textAlign: "center" }}>
                            <Button
                              size={"xs"}
                              bgGradient="linear(to-r, teal.300,  teal.100)"
                              _hover={{
                                bgGradient: "linear(to-r, teal.100,  teal.400)",
                              }}
                            >
                              {el.name}
                            </Button>
                          </Td>
                          <Td style={{ textAlign: "center" }}>
                            <Button
                              size={"xs"}
                              bgGradient="linear(to-r, teal.300,  teal.100)"
                              _hover={{
                                bgGradient: "linear(to-r, teal.100,  teal.400)",
                              }}
                            >
                              {el.order_id}
                            </Button>
                          </Td>

                          

                          <Td style={{ textAlign: "center" }}>
                            <Button
                              size={"xs"}
                              bgGradient="linear(to-r, teal.300,  teal.100)"
                              _hover={{
                                bgGradient: "linear(to-r, teal.100,  teal.400)",
                              }}
                            >
                              {el.biller_name}
                            </Button>
                          </Td>
                          <Td style={{ textAlign: "center" }}>
                            <Button
                              size={"xs"}
                              bgGradient="linear(to-r, teal.300,  teal.100)"
                              _hover={{
                                bgGradient: "linear(to-r, teal.100,  teal.400)",
                              }}
                            >
                              {el.quantity_returns}
                            </Button>
                          </Td>
                          <Td style={{ textAlign: "center" }}>
                            <Button
                              size={"xs"}
                              bgGradient="linear(to-r, teal.300,  teal.100)"
                              _hover={{
                                bgGradient: "linear(to-r, teal.100,  teal.400)",
                              }}
                            >
                              Rs {el.rate}
                            </Button>
                          </Td>
                          <Td style={{ textAlign: "center" }}>
                            <Button
                              size={"xs"}
                              bgGradient="linear(to-r, teal.300,  teal.100)"
                              _hover={{
                                bgGradient: "linear(to-r, teal.100,  teal.400)",
                              }}
                            >
                              Rs {el.returned_amount}
                            </Button>
                          </Td>
                        </Tr>
                      );
                    })}
              </Tbody>
            </Table>
            <Box>
              {paginationData.length === 0 && (
                <Alert
                  status="info"
                  variant="subtle"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  mt={4}
                >
                  <AlertIcon boxSize={8} />
                  No Matches Found
                </Alert>
              )}
            </Box>
          </TableContainer>
          <Flex justifyContent={"flex-end"} mt={5}>
            <Pagination page={page} setPage={setPage} totalPage={totalPage} />
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
};

export default ReturnItems;
