import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import Sidebar from "../../Components/Sidebar";
import Navbar from "../../Components/Navbar";
import ReturnItems from "./ReturnedItems";

const ReturnUpdates = () => {
  return (
    <Flex>
      <Sidebar />
      <Box flex="1" p="4">
        <Navbar />
        <ReturnItems />
      </Box>
    </Flex>
  );
};

export default ReturnUpdates;
