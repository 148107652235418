// import { useToast } from "@chakra-ui/react";
// import { Navigate } from "react-router-dom";

// const RequiredAuth = ({ children }) => {
//   const storedEmail = localStorage.getItem('email');
//   const storedPassword = localStorage.getItem('password');
//   const toast = useToast();

//   if (storedEmail === "admin@bharat.com" && storedPassword === "admin") {
//     return children;
//   }

//   return (
//     <>
//       <Navigate to="/" />
//       {toast({
//         position: "top",
//         title: "Please Login first",
//         description: "",
//         status: "warning",
//         duration: 3000,
//         isClosable: true,
//       })}
//     </>
//   );
// };

// export default RequiredAuth;


import { useToast } from "@chakra-ui/react";
import { Navigate } from "react-router-dom";

const RequiredAuth = ({ children }) => {
  const storedToken = localStorage.getItem("token")
  const toast = useToast();
  if(storedToken){
    return children;
  }
  return (
    <>
      <Navigate to="/" />
      {toast({
        position: "top",
        title: "Please Login first",
        description: "",
        status: "warning",
        duration: 3000,
        isClosable: true,
      })}
    </>
  );
};

export default RequiredAuth;
