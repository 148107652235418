export const GET_ADDEDSTOCKS_REQUEST = "GET_ADDEDSTOCKS_REQUEST";
export const GET_ADDEDSTOCKS_SUCCESS = "GET_ADDEDSTOCKS_SUCCESS";
export const GET_ADDEDSTOCKS_FAILURE = "GET_ADDEDSTOCKS_FAILURE";


export const EDIT_STOCK_REQUEST = "EDIT_STOCK_REQUEST";
export const EDIT_STOCK_SUCCESS = "EDIT_STOCK_SUCCESS";
export const EDIT_STOCK_FAILURE = "EDIT_STOCK_FAILURE";

export const GET_SALES_STOCKS_REQUEST = "GET_SALES_STOCKS_REQUEST";
export const GET_SALES_STOCKS_SUCCESS = "GET_SALES_STOCKS_SUCCESS";
export const GET_SALES_STOCKS_FAILURE = "GET_SALES_STOCKS_FAILURE";

export const GET_INVOICE_REQUEST = "GET_INVOICE_REQUEST";
export const GET_INVOICE_SUCCESS = "GET_INVOICE_SUCCESS";
export const GET_INVOICE_FAILURE = "GET_INVOICE_FAILURE";

export const GET_RETURNED_REQUEST = "GET_RETURNED_REQUEST";
export const GET_RETURNED_SUCCESS = "GET_RETURNED_SUCCESS";
export const GET_RETURNED_FAILURE = "GET_RETURNED_FAILURE";