// import React, { useState } from "react";
// import {
//   FormControl,
//   FormLabel,
//   Input,
//   Button,
//   VStack,
//   Box,
//   Flex,
//   useToast,
// } from "@chakra-ui/react";
// import { useNavigate } from "react-router-dom";

// const Login = () => {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const navigate = useNavigate();
//   const toast = useToast();



//   const handleLogin = () => {
//     if (email === "admin@bharat.com" && password === "admin") {
//       localStorage.setItem('email', email);
//       localStorage.setItem('password', password);
//       navigate("/dashboard");
//       toast({
//         position: "top",
//         title: "Successfully Logged In",
//         description: `admin@bharat.com has been successfully logged in`,
//         status: "success",
//         duration: 3000,
//         isClosable: true,
//       });

//     }
//     else {
//       toast({
//         position: "top",
//         title: "Incorrect Credentials",
//         description: `Incorrect Credentials`,
//         status: "error",
//         duration: 3000,
//         isClosable: true,
//       });
//     }
//   };

//   const handleKeyPress = (e) => {
//     if (e.key === "Enter") {
//       handleLogin();
//     }
//   };

//   return (
//     <Flex
//       minHeight="100vh"
//       alignItems="center"
//       justifyContent="center"
//       px={{ base: 4, sm: 6, md: 8 }} // Adjust padding based on screen size
//     >
//       <VStack spacing={4} align="stretch" w="100%" maxW="md">
//         <Box p={8} borderWidth={1} borderRadius={8} boxShadow="lg" w="100%">
//           <FormControl id="email">
//             <FormLabel>Email address</FormLabel>
//             <Input
//               type="email"
//               placeholder="Enter your email"
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//               required
//               onKeyPress={handleKeyPress}
//             />
//           </FormControl>
//           <FormControl mt={4} id="password">
//             <FormLabel>Password</FormLabel>
//             <Input
//               type="password"
//               placeholder="Enter your password"
//               value={password}
//               onChange={(e) => setPassword(e.target.value)}
//               required
//               onKeyPress={handleKeyPress}
//             />
//           </FormControl>
//           <Button
//             mt={4}
//             onClick={handleLogin}
//             bgGradient="linear(to-r, teal.300,  teal.100)"
//             _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
//             w="100%" // Full width on all screen sizes
//           >
//             Login
//           </Button>
//         </Box>
//       </VStack>
//     </Flex>
//   );
// };

// export default Login;



import React, { useState } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  VStack,
  Box,
  Flex,
  useToast,
  InputGroup,
  InputRightElement,
  IconButton,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const toast = useToast();

  const [loading, setLoading] = useState(false);
  const handleLoginwithAuth = async () => {
    try {
      setLoading(true);
      const response = await axios.post("https://inventoryapi.exploreanddo.com/api/login", { email, password });
      // console.log("login api",JSON.stringify(response.data))
      if (response.data.access_token) {
        localStorage.setItem('token', response.data.access_token);
        navigate("/dashboard");
        toast({
          position: "top",
          title: "Successfully Logged In",
          description: `${email} has been successfully logged in`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          position: "top",
          title: "Incorrect Credentials",
          description: "Incorrect Credentials",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Login failed:", error);
      toast({
        position: "top",
        title: "Login Failed",
        description: "An error occurred during login. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleForgetPassword = async () => {
    toast({
      position: "top",
      title: "Redirecting to password reset email link",
      status: "warning",
      duration: 3000,
      isClosable: true,
    });
    setTimeout(() => {
      navigate("/set-email");
    }, 3000);
  }

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleLoginwithAuth();
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Flex
      minHeight="100vh"
      alignItems="center"
      justifyContent="center"
      px={{ base: 4, sm: 6, md: 8 }} // Adjust padding based on screen size
    >
      <VStack spacing={4} align="stretch" w="100%" maxW="md">
        <Box p={8} borderWidth={1} borderRadius={8} boxShadow="lg" w="100%">
          <FormControl id="email">
            <FormLabel>Email address</FormLabel>
            <Input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              onKeyPress={handleKeyPress}
            />
          </FormControl>
          {/* <FormControl mt={4} id="password">
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              onKeyPress={handleKeyPress}
            />
          </FormControl> */}
          <FormControl mt={4} id="password">
            <FormLabel>Password</FormLabel>
            <InputGroup>
              <Input
                type={showPassword ? "text" : "password"}
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                onKeyPress={handleKeyPress}
              />
              <InputRightElement width="2.5rem">
                <IconButton
                  h="1.75rem"
                  size="sm"
                  onClick={handleTogglePassword}
                  icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                />
              </InputRightElement>
            </InputGroup>
          </FormControl>

          <Button
            mt={2}
            onClick={handleForgetPassword}
            variant="link"
            color="teal.500"
            textAlign="left"
            w="100%"
          >
            Forgot Password?
          </Button>
          {loading ? (<Button
            mt={4}
            onClick={handleLoginwithAuth}
            bgGradient="linear(to-r, teal.300,  teal.100)"
            _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
            w="100%"
            isLoading
            loadingText='Logging In'
          >Login
          </Button>) : (<Button
            mt={4}
            onClick={handleLoginwithAuth}
            bgGradient="linear(to-r, teal.300,  teal.100)"
            _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
            w="100%"
          >
            Login
          </Button>)}
        </Box>
      </VStack>
    </Flex>
  );
};

export default Login;
