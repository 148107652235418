import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  TableContainer,
  Tabs,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Tooltip as ChakraTooltip } from "@chakra-ui/react";
import Navbar from "../Components/Navbar";

import Sidebar from "../Components/Sidebar";
import { seeAddedStocks, seeSalesStocks } from "../Redux/app/action";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Pagination } from "../Components/Pagination";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);
const Dashboard = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(seeAddedStocks());
  }, [dispatch]);

  const addedStocks = useSelector((state) => state.AppReducer.addedStocks);
  //console.log("addedStocks", addedStocks);

  //////////////Total Sales////////////////


  useEffect(() => {
      dispatch(seeSalesStocks());
  }, [dispatch]);

  const salesStocks = useSelector((state) => state.AppReducer.salesStocks);

  // Get the current date in the format "YYYY-MM-DD"
  const currentDate = new Date().toISOString().split('T')[0];

  const todaysSales = salesStocks.filter((sale) => sale.created_at.includes(currentDate));

  const totalAmountToday = todaysSales.reduce((total, sale) => total + sale.amount, 0);
 
  const numberOfSalesToday = todaysSales.reduce((total, sale) => total + sale.quantity, 0);

  const numberOfProductSold = todaysSales.length
  
  // console.log("Total Amount Today:", totalAmountToday);
  // console.log("Number of Sales Today:", numberOfSalesToday);
  // console.log("Number of Product Sold:", numberOfProductSold);

  ////////////////////////////////////////

  const [tabIndex3, setTabIndex3] = useState(0);

  const handleTabsChange3 = (index) => {
    setTabIndex3(index);
  };


  const [hoveredIdTitle, setHoveredIdTitle] = useState(null);

  const handleHoverTitle = (id) => {
    setHoveredIdTitle(id);
  };

  const handleMouseLeaveTitle = () => {
    setHoveredIdTitle(null);
  };
  let filteredTask = addedStocks.filter((stock) => stock.quantity < 10);
  const [page, setPage] = useState(1);
  let perPage = 5;
  let totalPage = Math.ceil(filteredTask.length / perPage);
  let end = page * perPage;
  let start = end - perPage;

  let paginationData = [...filteredTask].slice(start, end);

  const chartData = {
    labels: ['Quantity Sale','Product Sale', 'Total Sale'],
    datasets: [
      {
        data: [numberOfSalesToday,numberOfProductSold, totalAmountToday],
        backgroundColor: ['#FF6384','red', '#36A2EB'],
        hoverBackgroundColor: ['#FF6384','red', '#36A2EB'],
      },
    ],
  };





// Assuming salesStocks and addedStocks are arrays of objects with 'order_id' property

const extractedPurchaseRates = salesStocks.map((salesStock) => {
  // Find the corresponding addedStock for the current salesStock order_id
  const matchingAddedStock = addedStocks.find((addedStock) => addedStock.order_id === salesStock.order_id);

  // Extract the purchase_rate or set a default value if not found
  const purchase_rate = matchingAddedStock ? matchingAddedStock.purchase_rate : null;

  // Return an object with order_id and purchase_rate
  return {
    order_id: salesStock.order_id,
    purchase_rate: purchase_rate,
  };
});

// console.log(extractedPurchaseRates);

const dataWithPurchaseTotal = salesStocks.map((el) => {
  // Find the corresponding addedStock for the current paginationData order_id
  const matchingAddedStock = addedStocks.find((addedStock) => addedStock.order_id === el.order_id);

  // Calculate the total purchase rate (purchase rate * quantity)
  const totalPurchaseRate = matchingAddedStock ? matchingAddedStock.purchase_rate * el.quantity : 0;

  // Return the original data along with the new totalPurchaseRate property
  return {
      ...el,
      totalPurchaseRate: totalPurchaseRate,
  };
});



const todaysSalesProfit = dataWithPurchaseTotal.filter((sale) => sale.created_at.includes(currentDate));

const totalProfitToday = todaysSalesProfit.reduce(
  (total, sale) => total + (sale.amount - sale.totalPurchaseRate),
  0
);

//console.log("totalProfitToday", totalProfitToday);

const profitData = {
  labels: ['Total Profit'],
  datasets: [
    {
      data: [totalProfitToday],
      backgroundColor: ['green'],
      hoverBackgroundColor: ['green'],
    },
  ],
};

return (
    <Flex>
      <Sidebar />
      <Box flex="1" p="4">
        <Navbar />
        <Flex
          flexWrap="wrap"
          mb={4}
          mt={10}
          direction={{ base: "column", md: "row" }}
          justifyContent={{ base: "flex-start", md: "space-around" }}
        >
              <Card
            w={250}
            bgGradient="linear(to-r, teal.300,  teal.100)"
            _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
          >
            <CardBody>
              <Stack
                spacing="3"
                textAlign="center"
                align="center"
                justify="center"
              >
                <Heading size="sm">Today's Sold Product</Heading>
                <Button size="sm" color={"cyan.600"} w={20}>
                  {numberOfProductSold}
                </Button>
              </Stack>
            </CardBody>
          </Card>
          <Card
            w={250}
            bgGradient="linear(to-r, teal.300,  teal.100)"
            _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
          >
            <CardBody>
              <Stack
                spacing="3"
                textAlign="center"
                align="center"
                justify="center"
              >
                <Heading size="sm">Today's Sale Quantity</Heading>
                <Button size="sm" color={"cyan.600"} w={20}>
                  {numberOfSalesToday}
                </Button>
              </Stack>
            </CardBody>
          </Card>
          <Card
            bgGradient="linear(to-r, teal.300,  teal.100)"
            _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
            w={250}
          >
            <CardBody>
              <Stack
                spacing="3"
                textAlign="center"
                align="center"
                justify="center"
              >
                <Heading size="sm">Total Sales Amount</Heading>
                <Button size="sm" color={"cyan.600"} w={20}>
                  Rs {totalAmountToday}
                </Button>
              </Stack>
            </CardBody>
          </Card>
          <Card
            bgGradient="linear(to-r, teal.300,  teal.100)"
            _hover={{ bgGradient: "linear(to-r, teal.100,  teal.400)" }}
            w={250}
          >
            <CardBody>
              <Stack
                spacing="3"
                textAlign="center"
                align="center"
                justify="center"
              >
                <Heading size="sm">Today's Profit</Heading>
                <Button size="sm" color={"cyan.600"} w={20}>
                  Rs {totalProfitToday}
                </Button>
              </Stack>
            </CardBody>
          </Card>
    
        </Flex>
        <Flex
          direction={{ base: "column", md: "row" }}
          justifyContent={{ base: "flex-start", md: "space-between" }}
        >
          <Box
            w={{ base: "100%", md: "70%" }}
            p={{ base: "5", md: "5" }}
            borderRadius={10}
            boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
          >
            <Heading
              textAlign={"start"}
              size={"sm"}
              p={2}
              textDecoration={"underline"}
            >
              Low On Stocks
            </Heading>
            <TableContainer>
              <Table variant="simple" colorScheme="teal" boxShadow={"md"}>
                <Thead>
                  <Tr boxShadow={"md"}>
                    <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                      Stock Date
                    </Th>
                    <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                      Name
                    </Th>
                    <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                    HSN/SAC
                    </Th>
                    <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                      Rack Position
                    </Th>
                    <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                      Quantity
                    </Th>
                  </Tr>
                </Thead>

                <Tbody>
                  {paginationData.length > 0 &&
                    paginationData?.map((el) => (
                      <Tr key={el.id} boxShadow={"md"}>
                        <Link to="/stockcontrol">
                          <Td style={{ textAlign: "center" }}>
                            <Button
                              size={"xs"}
                              fontWeight={"bold"}
                              bgGradient="linear(to-r, teal.300,  teal.100)"
                              _hover={{
                                bgGradient: "linear(to-r, teal.100,  teal.400)",
                              }}
                            >
                              {el.stock_date}
                            </Button>
                          </Td>
                        </Link>
                        <Td style={{ textAlign: "center" }}>
                          <Button
                            size={"xs"}
                            fontWeight={"bold"}
                            bgGradient="linear(to-r, teal.300,  teal.100)"
                            _hover={{
                              bgGradient: "linear(to-r, teal.100,  teal.400)",
                            }}
                          >
                            {el.name}
                          </Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                            <Button
                              size={"xs"}
                              bgGradient="linear(to-r, teal.300,  teal.100)"
                              _hover={{
                                bgGradient: "linear(to-r, teal.100,  teal.400)",
                              }}
                            >
                              {el.order_id}
                            </Button>
                          </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Button
                            size={"xs"}
                            fontWeight={"bold"}
                            bgGradient="linear(to-r, teal.300,  teal.100)"
                            _hover={{
                              bgGradient: "linear(to-r, teal.100,  teal.400)",
                            }}
                          >
                            {el.rack_pos}
                          </Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Button
                            size={"xs"}
                            fontWeight={"bold"}
                            bgGradient="linear(to-r, teal.300,  teal.100)"
                            _hover={{
                              bgGradient: "linear(to-r, teal.100,  teal.400)",
                            }}
                          >
                            {el.quantity}
                          </Button>
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
              <Box>
                {paginationData.length === 0 && (
                  <Alert
                    status="info"
                    variant="subtle"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    mt={4}
                  >
                    <AlertIcon boxSize={8} />
                    No Stocks Is Less Than 10 Quantity
                  </Alert>
                )}
              </Box>
            </TableContainer>
            <Flex justifyContent={"flex-end"} mt={5}>
              <Pagination page={page} setPage={setPage} totalPage={totalPage} />
            </Flex>
          </Box>
          <Box
            w={{ base: "100%", md: "25%" }}
            p={{ base: "5", md: "5" }}
            borderRadius={10}
            boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
          >
            <Box>
              <Box>
                <Tabs index={tabIndex3} onChange={handleTabsChange3}>
                  <Flex justifyContent={{ base: "center", md: "center" }}>
                    <TabList
                      textAlign="center"
                      size="sm"
                      spacing={5}
                      justifyContent="center"
                    >
                      <Tab fontWeight="bold" fontSize={"sm"}>
                        Today's Sale Chart
                      </Tab>
                      <Tab fontWeight="bold" fontSize={"sm"}>
                        Today's Profit Chart
                      </Tab>
                    </TabList>
                  </Flex>
                  <TabPanels>
                    
                    <TabPanel>
                      <Doughnut data={chartData} />
                    </TabPanel>
                    <TabPanel>
                      <Doughnut data={profitData} />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Box>
            </Box>
          </Box>
        </Flex>
      </Box>
    
    </Flex>
  );
};

export default Dashboard;
