import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import Navbar from "../Components/Navbar";
import Sidebar from "../Components/Sidebar";
import SalesStocks from "./SalesStocks";
const Sales = () => {
  return (
    <Flex>
      <Sidebar />
      <Box flex="1" p="4">
        <Navbar />
        <SalesStocks />
      </Box>
    </Flex>
  );
};

export default Sales;
