
import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import Navbar from "../Components/Navbar";

import AddingStocks from "./AddingStocks";
import Sidebar from "../Components/Sidebar";
const Stocks = () => {




  return (
    <Flex>
      <Sidebar />
      <Box flex="1" p="4">
        <Navbar />
       
        <AddingStocks />
      </Box>
    </Flex>
  );
};

export default Stocks;
