import {
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Button,
    Flex,
    Heading,
    Input,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Alert,
    AlertIcon,
    InputRightElement,
    InputGroup,
} from "@chakra-ui/react";
import React, { useEffect,  useState } from "react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { getInvoiceDteails } from "../Redux/app/action";
import { Pagination } from "../Components/Pagination";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { BsCalendar3 } from "react-icons/bs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./datepicker-custom.css";
const ReportsDetails = () => {
   
    const dispatch = useDispatch();
   
    useEffect(() => {
        dispatch(getInvoiceDteails());
    }, [dispatch]);

    const invoiceDetails = useSelector((state) => state.AppReducer.invoiceDetails);
    //console.log("invoiceDetails", invoiceDetails);

    const [searchQuery, setSearchQuery] = useState("");
    const [page, setPage] = useState(1);
    let perPage = 10;

    // Check if taskData is an array, otherwise initialize it as an empty array
    let invoiceDataArray = Array.isArray(invoiceDetails) ? invoiceDetails : [invoiceDetails];

    // Calculate total pages based on the filtered data
    let totalPage = Math.ceil(invoiceDataArray.length / perPage);
    let end = page * perPage;
    let start = end - perPage;

    // Apply filtering on the taskDataArray and handle non-array cases
    let filteredData = Array.isArray(invoiceDataArray)
        ? invoiceDataArray.filter(
            (el) =>
                (el.biller_name &&
                    el.biller_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
                (el.biller_address &&
                    el.biller_address.toLowerCase().includes(searchQuery.toLowerCase())) ||
                (el.biller_city &&
                    el.biller_city.toLowerCase().includes(searchQuery.toLowerCase())) ||
                (el.biller_mobile &&
                    el.biller_mobile.toString().includes(searchQuery.toLowerCase())) ||
                (el.invoice_no &&
                    el.invoice_no.toLowerCase().includes(searchQuery.toLowerCase())) ||
                (el.invoice_date &&
                    el.invoice_date.toLowerCase().includes(searchQuery.toLowerCase()))
        )
        : [invoiceDataArray];

    // Apply pagination to the filteredData
    let paginationData = filteredData.slice(start, end);

    //console.log("paginationData", paginationData);

    // Handling Pagination End Here
    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState(null);

    const handleStartDateChange = (date) => {
        setSelectedStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setSelectedEndDate(date);
    };

    const filteredDataa = paginationData.filter((item) => {
        const itemDate = new Date(item.invoice_date);
        return (
            (!selectedStartDate || itemDate >= selectedStartDate) &&
            (!selectedEndDate || itemDate <= selectedEndDate)
        );
    });
//console.log("filteredDataa",filteredDataa)
    return (
        <Flex>
            <Box pl={{ base: "none", md: "10" }} w={{ base: "100%", md: "100%" }}>
                <br />
                <br />
                <Heading size={"md"} textAlign={"start"}>
                    Invoice Report
                </Heading>
                <Flex justifyContent={"space-between"}>
                    <Breadcrumb
                        spacing="8px"
                        mt={6}
                        mb={6}
                        separator={<ChevronRightIcon color="gray.500" />}
                    >
                        <BreadcrumbItem>
                            <BreadcrumbLink href="/dashboard">Dashboard</BreadcrumbLink>
                        </BreadcrumbItem>

                        <BreadcrumbItem>
                            <BreadcrumbLink href="#">Invoice Report</BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>
                    <Flex justifyContent={"space-between"} gap={3}>

                        {/* <Flex justifyContent={"space-between"} gap={1}>
                            <DatePicker
                                selected={selectedStartDate}
                                onChange={handleStartDateChange}
                                placeholderText="From Date"
                                dateFormat="yyyy-MM-dd"
                                customInput={
                                    <InputGroup>
                                        <Input
                                            value={selectedStartDate ? format(selectedStartDate, 'dd-MM-yyyy') : ''}
                                            placeholder="From Date"
                                            style={{
                                                border: '1px solid gray',
                                                fontWeight: 'inherit',
                                            }}
                                        />
                                        <InputRightElement>
                                            <BsCalendar3 />
                                        </InputRightElement>
                                    </InputGroup>
                                }
                            />
                            <DatePicker
                                selected={selectedEndDate}
                                onChange={handleEndDateChange}
                                placeholderText="To Date"
                                dateFormat="yyyy-MM-dd"
                                customInput={
                                    <InputGroup>
                                        <Input
                                            value={selectedEndDate ? format(selectedEndDate, 'dd-MM-yyyy') : ''}
                                            placeholder="To Date"
                                            style={{
                                                border: '1px solid gray',
                                                fontWeight: 'inherit',
                                            }}
                                        />
                                        <InputRightElement>
                                            <BsCalendar3 />
                                        </InputRightElement>
                                    </InputGroup>
                                }
                            />
                        </Flex> */}


                        <Input
                            htmlSize={20}
                            width={500}
                            placeholder="Search By Invoice No, Name, Address, Mobile..."
                            focusBorderColor="green.400"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </Flex>
                </Flex>
                <Box>
                    <TableContainer>
                        <Table variant="simple" colorScheme="teal" boxShadow={"md"}>
                            <Thead>
                                <Tr boxShadow={"md"}>
                                    <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                                        See Details
                                    </Th>
                                    <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                                        Invoice No
                                    </Th>
                                    <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                                        Billing Date
                                    </Th>
                                    <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                                        Biller Name
                                    </Th>
                                   
                                  
                                    <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                                        Biller Address
                                    </Th>
                                    <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                                        Biller Mobile
                                    </Th>
                                    <Th boxShadow={"md"} style={{ textAlign: "center" }}>
                                        Biller City
                                    </Th>

                                </Tr>
                            </Thead>

                            <Tbody>
                                {filteredDataa.length > 0 &&
                                    filteredDataa?.map((el) => {
                                        return (
                                            <Tr key={el.id} boxShadow={"md"}>
                                                <Td style={{ textAlign: "center" }}>
                                                    <Link to={{ pathname: `/invoice-details/${el.id}`}}>
                                                        <Button size={"xs"} bgGradient="linear(to-r, teal.300,  teal.100)"
                                                            _hover={{
                                                                bgGradient: "linear(to-r, teal.100,  teal.400)",
                                                            }}

                                                        >View</Button>
                                                    </Link>
                                                </Td>
                                                <Td style={{ textAlign: "center" }}>
                                                    <Button size={"xs"} bgGradient="linear(to-r, teal.300,  teal.100)"
                                                        _hover={{
                                                            bgGradient: "linear(to-r, teal.100,  teal.400)",
                                                        }}>{el.invoice_no}</Button>
                                                </Td>
                                                <Td style={{ textAlign: "center" }}>
                                                    <Button size={"xs"} bgGradient="linear(to-r, teal.300,  teal.100)"
                                                        _hover={{
                                                            bgGradient: "linear(to-r, teal.100,  teal.400)",
                                                        }}>{format(new Date(el.invoice_date), 'dd-MM-yyyy')}</Button>
                                                </Td>
                                                <Td style={{ textAlign: "center" }}>
                                                    <Button size={"xs"} bgGradient="linear(to-r, teal.300,  teal.100)"
                                                        _hover={{
                                                            bgGradient: "linear(to-r, teal.100,  teal.400)",
                                                        }}>{el.biller_name}</Button>
                                                </Td>
                                              
                                               

                                                <Td style={{ textAlign: "center" }}>
                                                    <Button size={"xs"} bgGradient="linear(to-r, teal.300,  teal.100)"
                                                        _hover={{
                                                            bgGradient: "linear(to-r, teal.100,  teal.400)",
                                                        }}>
                                                        {el.biller_address}
                                                    </Button>
                                                </Td>
                                                <Td style={{ textAlign: "center" }}>
                                                    <Button size={"xs"} bgGradient="linear(to-r, teal.300,  teal.100)"
                                                        _hover={{
                                                            bgGradient: "linear(to-r, teal.100,  teal.400)",
                                                        }}>{el.biller_mobile}</Button>
                                                </Td>
                                                <Td style={{ textAlign: "center" }}>
                                                    <Button size={"xs"} bgGradient="linear(to-r, teal.300,  teal.100)"
                                                        _hover={{
                                                            bgGradient: "linear(to-r, teal.100,  teal.400)",
                                                        }}>{el.biller_city}</Button>
                                                </Td>
                                            </Tr>
                                        );
                                    })}
                            </Tbody>
                        </Table>
                        <Box>
                            {filteredDataa.length === 0 && (
                                <Alert
                                    status="info"
                                    variant="subtle"
                                    flexDirection="column"
                                    alignItems="center"
                                    justifyContent="center"
                                    mt={4}
                                >
                                    <AlertIcon boxSize={8} />
                                    No Matches Found
                                </Alert>
                            )}
                        </Box>
                    </TableContainer>
                    <Flex justifyContent={"flex-end"} mt={5}>
                        <Pagination page={page} setPage={setPage} totalPage={totalPage} />
                    </Flex>
                </Box>
            </Box>
        </Flex>
    );
};

export default ReportsDetails;
